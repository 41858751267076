import { render, staticRenderFns } from "./form.vue?vue&type=template&id=155ebf2a"
import script from "./form.vue?vue&type=script&lang=js"
export * from "./form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/search/form/location.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fvsts%2Fwork%2F1%2Fs%2Fcomponents%2Fsearch%2Flocation%2Fform.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "~/locales/common/location.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fhome%2Fvsts%2Fwork%2F1%2Fs%2Fcomponents%2Fsearch%2Flocation%2Fform.vue&lang=yaml&external"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZSpinner: require('/home/vsts/work/1/s/components/style-guide/spinner.vue').default,FormInputClearButton: require('/home/vsts/work/1/s/components/form/input/clear-button.vue').default})
