
let searchTimer = null

import ClickOutside from '@/vendor/vue-click-outside'
import useGoogleMaps from '~/lib/useGoogleMaps'
import { getNewUUID } from '~/lib/useUtils'

export default {
  directives: {
    ClickOutside
  },

  props: {
    location: {
      type: Object,
      default: null
    },

    label: {
      type: String,
      default: ''
    },

    showLabel: {
      type: Boolean,
      default: false
    },

    isVisbible: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      search: this.location?.fullName ?? '',
      predictionsVisible: false,
      searchPredictions: [],
      searchPredictionsLoading: false,
      isSettingPlace: false,
      placeIsFocused: false
    }
  },

  computed: {
    uid() {
      return `search-location-${getNewUUID()}`
    },

    isPredictionsShown() {
      return this.predictionsVisible && this.searchPredictions.length > 0
    }
  },

  watch: {
    isVisbible: {
      handler: function (newVal) {
        if (newVal) {
          setTimeout(() => {
            this.$refs.place.focus()
          }, 200)
        }
      },

      immediate: true
    },

    search: function (newVal) {
      this.predictionsVisible = false

      if (newVal && !this.isSettingPlace) {
        this.getPlacesPredictions(newVal)
      }
    },

    '$search.parameters.location.fullName': function (newVal) {
      this.isSettingPlace = true
      this.search = newVal
      if (!newVal) {
        this.searchPredictions = []
      }
      this.isSettingPlace = false
    }
  },

  methods: {
    inputBlur() {
      // Clear value on desktop
      if (!this.isPredictionsShown) {
        this.$emit('blur')
      }
    },

    showPredictions() {
      if (this.search && this.searchPredictions.length === 0) {
        this.getPlacesPredictions(this.search)
      } else {
        this.predictionsVisible = true
      }
      this.placeIsFocused = true
    },

    hidePredictions(event) {
      if (!event.composedPath().includes(this.$refs.place)) {
        this.predictionsVisible = false
        this.placeIsFocused = false

        this.$emit('blur')
      }
    },

    getPlacesPredictions(searchText) {
      this.search = searchText
      this.searchPredictions = []
      this.searchPredictionsLoading = false
      this.predictionsVisible = false

      if (!searchText) return

      this.searchPredictionsLoading = true

      clearTimeout(searchTimer)
      searchTimer = setTimeout(
        async () => {
          const { placePredictions } = await useGoogleMaps()
          const predictions = await placePredictions(searchText, this.$config.supportedCountries)
          this.searchPredictions = predictions
          this.searchPredictionsLoading = false
          this.predictionsVisible = Boolean(this.search)
        },
        this.$device.isMobileOrTablet ? 1000 : 500
      )
    },

    setPlace(place) {
      this.isSettingPlace = true
      this.placeIsFocused = false

      this.search = place.description

      this.$emit('update', place)

      this.$nextTick(() => {
        this.predictionsVisible = false
        this.isSettingPlace = false
      })
    },

    clearPlace() {
      this.search = ''
      this.searchPredictions = []
      this.$emit('clear')
    },

    keyUp() {
      if (this.isPredictionsShown) {
        this.setPlace(this.searchPredictions[0])
      }
    }
  }
}
